<template>
  <div>
    <section class="about-area about-jc section-padding-120 bg-gray">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-red font-italic">Сургалт</h1>
        </div>
        <div class="hero--content--area">
          <div class="row justify-content-center g-4">
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s1.mp4')"
                src="@/assets/training/1.jpg"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s2.mp4')"
                src="@/assets/training/2.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s3.mp4')"
                src="@/assets/training/3.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s4.mp4')"
                src="@/assets/training/4.png"
                alt=""
              />
            </div>

            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s5.mp4')"
                src="@/assets/training/5.png"
                alt=""
              />
            </div>
            <!-- Single Hero Card-->
            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <img
                class="cursor-pointer"
                @click="play('s6.mp4')"
                src="@/assets/training/6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal :width="320" :height="240" name="video">
      <video autoplay width="320" height="240" controls>
        <source :src="'/media/' + video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </modal>
  </div>
</template>

<script>
export default {
  name: "Training",
  data() {
    return {
      video: "",
    };
  },
  mounted() {},
  methods: {
    play(value) {
      this.video = value;
      this.$modal.show("video");
    },
  },
};
</script>
